<template>
    <a-drawer
        :visible="visible"
        @close="visible = false"
        class="task_edit"
        :width="drawerWidth"
        :zIndex="9999"
        :title="edit ? 'Редактировать спринт' : 'Создать спринт'"
        :after-visible-change="afterVisibleChange">
        <div ref="sprintFormWrapper" class="d_body"> 
            <a-spin :spinning="sprintLoading" class="w-full">
                <a-form-model
                    ref="sprintForm"
                    :model="form"
                    :rules="rules">
                    <a-form-model-item
                        ref="name"
                        label="Название спринта"
                        prop="name">
                        <a-input
                            v-model="form.name"
                            size="large"
                            placeholder="Полное наименование спринта" />
                    </a-form-model-item>
                    <a-form-model-item
                        ref="projects"
                        label="Проект"
                        prop="projects">
                        <ProjectDrawer 
                            v-model="form.projects" 
                            inputSize="large" />
                    </a-form-model-item>
                    <a-form-model-item
                        ref="duration"
                        label="Длительность"
                        prop="duration">
                        <a-select 
                            v-model="form.duration" 
                            :getPopupContainer="getPopupContainer"
                            size="large">
                            <a-select-option :value="7">
                                Неделя
                            </a-select-option>
                            <a-select-option :value="14">
                                Две недели
                            </a-select-option>
                            <a-select-option :value="28">
                                Месяц
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item
                        ref="target"
                        label="Цель спринта"
                        prop="target">
                        <a-input
                            v-model="form.target"
                            size="large"
                            placeholder="Введите цель спринта" />
                    </a-form-model-item>
                    <a-form-model-item
                        ref="expected_result"
                        label="Ожидаемый результат"
                        prop="expected_result">
                        <a-select 
                            v-model="form.expected_result" 
                            mode="tags" 
                            class="w-full"
                            size="large" 
                            :getPopupContainer="getPopupContainer"
                            placeholder="Введите ожидаемый результат" 
                            notFoundContent="Введите несколько значений">
                        </a-select>
                    </a-form-model-item>
                    <a-button 
                        type="primary" 
                        size="large" 
                        :loading="loading"
                        block
                        @click="formSubmit()">
                        <template v-if="edit">
                            Сохранить
                        </template>
                        <template v-else>
                            Добавить спринт
                        </template>
                    </a-button>
                </a-form-model>
            </a-spin>
        </div>
    </a-drawer> 
</template>

<script>
import eventBus from '@/utils/eventBus'
import ProjectDrawer from './ProjectDrawer.vue'
export default {
    components: {
        ProjectDrawer
    },
    data(){
        return {
            edit: false,
            visible: false,
            sprintLoading: false,
            inject: false,
            back: false,
            form: {
                name: "",
                target: "",
                duration: null,
                expected_result: [],
                projects: []
            },
            rules: {
                name: [
                    { required: true, message: this.$t('task.field_require'), trigger: 'blur' },
                    { max: 255, message: this.$t('task.field_min_require'), trigger: 'blur' }
                ],
                duration: [
                    { required: true, message: this.$t('task.field_require'), trigger: 'blur' }
                ],
                projects: [
                    { required: true, message: this.$t('task.field_require'), trigger: 'blur' }
                ]
            },
            loading: false
        }
    },
    computed: {
        isInject() {
            return this.inject ? `_inject` : ''
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        drawerWidth() {
            if(this.windowWidth > 1200)
                return 821
            else if(this.windowWidth <= 1200 && this.windowWidth > 821) {
                return '95%'
            } else {
                return '100%'
            }
        },
    },
    methods: {
        getPopupContainer() {
            return this.$refs.sprintFormWrapper
        },
        formSubmit() {
            this.$refs.sprintForm.validate(async valid => {
                if (valid) {
                    const queryData = {...this.form}
                    if(queryData.projects?.length)
                        queryData.projects = queryData.projects.map(item => item.id)
                    if(this.edit) {
                        try {
                            this.loading = true
                            const { data } = await this.$http.put(`/tasks/sprint/${queryData.id}/update/`, queryData)
                            if(data) {
                                this.$message.success("Спринт обновлен")
                                eventBus.$emit(`update_sprints_list${this.isInject}`)
                                if(this.back) {
                                    const query = Object.assign({}, this.$route.query)
                                    if(query.sprint && Number(query.sprint) !== queryData.id || !query.sprint) {
                                        query.sprint = queryData.id
                                        this.$router.push({query})
                                    }
                                }
                                this.visible = false
                            }
                        } catch(e) {
                            console.log(e)
                        } finally {
                            this.loading = false
                        }
                    } else {
                        try {
                            this.loading = true
                            const { data } = await this.$http.post('/tasks/sprint/create/', queryData)
                            if(data) {
                                this.$message.success("Спринт создан")
                                eventBus.$emit(`update_sprints_list${this.isInject}`)
                                this.visible = false
                            }
                        } catch(e) {
                            console.log(e)
                        } finally {
                            this.loading = false
                        }
                    }
                } else {
                    return false;
                }
            })
        },
        afterVisibleChange(vis) {
            if(!vis) {
                this.edit = false
                this.inject = false
                this.back = false
                this.form = {
                    name: "",
                    target: "",
                    duration: null,
                    expected_result: [],
                    projects: []
                }
            }
        },
        async getSprint(sprint) {
            try {
                this.sprintLoading = true
                const { data } = await this.$http.get(`/tasks/sprint/${sprint.id}/`)
                if(data) {
                    this.form = {...data}
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.sprintLoading = false
            }
        }
    },
    mounted() {
        eventBus.$on('add_sprint', injectParams => {
            if(Object.keys(injectParams)?.length) {
                this.form = {
                    ...this.form,
                    ...injectParams
                }
                if(injectParams.inject)
                    this.inject = injectParams.inject
            }
            this.visible = true
        })
        eventBus.$on('edit_sprint', sprint => {
            this.edit = true
            this.visible = true
            if(sprint.inject)
                this.inject = sprint.inject
            if(sprint.back)
                this.back = sprint.back
            this.getSprint(sprint)
        })
    },
    beforeDestroy() {
        eventBus.$off('add_sprint')
        eventBus.$off('edit_sprint')
    }
}
</script>