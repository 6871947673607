import { render, staticRenderFns } from "./ProjectDrawer.vue?vue&type=template&id=2485a314&scoped=true"
import script from "./ProjectDrawer.vue?vue&type=script&lang=js"
export * from "./ProjectDrawer.vue?vue&type=script&lang=js"
import style0 from "./ProjectDrawer.vue?vue&type=style&index=0&id=2485a314&prod&lang=scss&scoped=true"
import style1 from "./ProjectDrawer.vue?vue&type=style&index=1&id=2485a314&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2485a314",
  null
  
)

export default component.exports