<template>
    <div class="" :class="useBackground && 'useBackground'">
        <div class="panel">
            <div class="panel__chart">
                <p class="text-xl mb-7">Задачи</p>
                <div class="flex items-center">
                    <apexchart
                        :key="apexchartKey"
                        type="donut" 
                        :options="chartOptions" 
                        class="chart"
                        :width="chartSize.width"
                        :height="chartSize.height"
                        :series="series" />
                    <div class="max-h-[100px] overflow-y-scroll">
                        <div v-for="(label, index) in labels" :key="label" class="legend__row">
                            <div class="list_marker" :style="`background: ${palette[index]}`" />
                            <div>
                                <div>
                                    {{ label }}
                                </div>
                                <div>
                                    {{ series[index] }} {{ $t('invest.mlnTenge') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel__table">
                <div class="chart-table">
                    <div class="chart-table__row" v-for="i in 9" :key="i">
                        <span>1 этап</span>
                        <span>29.01.2024</span>
                        <span>Выполнено</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapState } from 'vuex'
import eventBus from '@/utils/eventBus'
export default {
    props: {
        fakeData: {
            type: Object,
            default: null,
        },
        item: {
            type: Object,
            // required: true,
            default: null,
        },
        graphColor: {
            type: String,
            default: '#f9f9f9'
        },
        useBackground: {
            type: Boolean,
            default: true
        }
    },
    components: {
        apexchart: VueApexCharts
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth
        }),

        series() {
            return [12,10,10,10,10,10,10,10,10,10,21]
        },
        chartSize() {
            if(this.windowWidth < 1800) {
                if(this.windowWidth < 1650) {
                    if(this.windowWidth < 768) {
                        return {
                            width: '230px',
                            height: '230px'
                        }
                    } else {
                        return {
                            width: '180px',
                            height: '180px'
                        }
                    }
                } else {
                    return {
                        width: '200px',
                        height: '200px'
                    }
                }
            } else {
                return {
                    width: '230px',
                    height: '230px'
                }
            }
        },
        // allPrice() {
        //     return Number(this.item.funds)
        // },
        // totalAmount() {
        //     return this.item.funding_sources.reduce((sum, each) => sum + Number(each.amount), 0)
        // },
        // allPriceStat() {
        //     return this.allPrice - this.totalAmount
        // },
        labels() {
            // let labels = this.item.funding_sources.map(each => each.funding_source.name)
            // labels.push('')
            // return labels
            return ["РБ – республиканский бюджет","За счет инвестора","Планируется","МБ – местный бюджет","МБ – местный бюджет","НФ – Национальный Фонд","Планируется","РБ – республиканский бюджет","За счет инвестора","ДИ – дополнительные инвестиции",""]
        },
        // series() {
        //     let series = this.item.funding_sources.map(each => Number(each.amount))
        //     series.push(this.allPriceStat)
        //     return series
        // },
        // percent() {
        //     return this.allPrice ? parseInt((this.totalAmount * 100/this.allPrice).toFixed(2)) : 0
        // },
        palette() {
            const numColors = this.labels.length
            const palette = []
            const saturation = 60
            const lightness = 50

            for (let i = 0; i < numColors; i++) {
                const hue = Math.round((360 / numColors) * i)
                palette.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
            }
            palette.push(this.graphColor)
            return palette
        }
    },
    data() {
        return {
            tab: 1,
            seriesData: [],
            apexchartKey: new Date(),
            chartOptions: {
                stroke: {
                    width: 0
                },
                legend: {
                    show: false
                },
                colors: [],
                dataLabels: {
                    enabled: false,
                    value: {
                        formatter(value) {
                            // eslint-disable-next-line radix
                            return `${parseInt(value)}%`
                        }
                    },
                    style: {
                        fontSize: '10px',
                        colors: ['#333']
                    },
                    dropShadow: {
                        enabled: false
                    }
                },
                chart: {
                    foreColor: '#000'
                },
                plotOptions: {
                    pie: {
                        customScale: 1,
                        startAngle: 1,
                        donut: {
                            size:'90%',
                            labels: {
                                show: true,
                                name: {
                                    fontSize: '2rem',
                                    color: '#000',
                                    offsetY: 20
                                },
                                value: {
                                    fontSize: '1.8rem',
                                    fontWeight: 300,
                                    offsetY: -14,
                                    formatter: (value) => {
                                        // eslint-disable-next-line radix
                                        return `${parseInt((parseInt(value) * 100/43).toFixed(2))}%`
                                    },
                                },
                                total: {
                                    show: true,
                                    fontSize: '13px',
                                    fontWeight: 400,
                                    label: this.$t('invest.invest_sobrano_sredstv'),
                                    formatter: () => `${43}%`
                                },
                            }
                        }
                    },
                },
                labels: []
            }
        }
    },
    watch: {
        item: {
            handler: 'updateChartData',
            immediate: true,
            deep: true,
        },
    },
    methods: {
        updateChartData() {
            // if (this.item && this.item.funding_sources) {
            this.apexchartKey = Date.now(),
            this.chartOptions.labels = this.labels
            this.seriesData = this.series
            this.chartOptions.colors = this.palette
            // }
        }        
    },
    mounted() {
        eventBus.$on('update_price_chart', () => {
            this.updateChartData()
        })
    },
    beforeDestroy() {
        eventBus.$off('update_price_chart')
    }
}
</script>

<style lang="scss" scoped>

.panel {
    display: flex;
    justify-content: space-between;
    // padding: 25px 30px;
    max-height: 330px;
    background-color: #FAFAFA;
    border-radius: 12px;
}

.panel__table {
    padding: 50px;
}

.chart-table {
    max-height: 234px;
    padding-right: 12px;
    margin-right: -12px;
    overflow-y: auto;
}

.chart-table__row {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    column-gap: 40px;
    padding: 20px 0;
    line-height: 1;
    &:not(:first-child) {
        border-top: 1px solid rgba(97, 121, 250, 0.3);
    }
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        padding-bottom: 0;
    }
}

.graph{
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
        grid-template-columns: 160px 1fr;
    }
    @media (min-width: 1600px) {
        grid-template-columns: 160px 1fr;
    }
    @media (min-width: 1800px) {
        grid-template-columns: 200px 1fr;
    }
    &__right{
        display: flex;
        justify-content: flex-start;
    }
    &.useBackground{
        &::v-deep{
            circle{
                fill: #f9f9f9;
            }
        }
    }
    &::v-deep{
        .chart{
            margin-left: -20px;
        }
    }
    .graph_list{
        margin-top: 10px;
        font-size: 13px;
        &__item{
            display: flex;
            align-items: baseline;
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
    }
    .graph_label{
        color: #000000;
        opacity: 0.6;
        font-size: 13px;
        margin-bottom: 6px;
    }
    .graph_value{
        color: #000000;
        font-size: 24px;
        line-height: 24px;
    }
}
.legend__row {
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 10px;
    }
}
.list_marker {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #f9f9f9;
    margin-right: 10px;
}

.panel__chart {
    padding: 30px 25px;
}
</style>