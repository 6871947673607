<template>
    <div 
        class="workg_draw_input ant-input flex items-center relative flex-wrap" 
        :class="size">
        <template v-if="value && value.length">
            <a-tooltip 
                v-for="item in value"
                :key="item.id"
                :title="item.name" 
                class="mr-1">
                <a-tag 
                    color="blue" 
                    class="tag_block truncate" 
                    @click="open()">
                    <div class="flex items-center truncate">
                        <div class="mr-1">
                            <a-avatar 
                                :size="15" 
                                icon="team" 
                                :key="item.id"
                                :src="workgroupLogoPath(item)" />
                        </div>
                        {{item.name}}
                    </div>
                </a-tag>
            </a-tooltip>
        </template>
        <a-button
            @click="open()"
            type="link"
            :icon="(!value && !value.length) && 'plus'"
            class="px-0 change_btn">
            {{value ? $t('task.change') : $t('task.select')}}
        </a-button>
        <a-button
            v-if="value && value.length"
            @click="clear()"
            type="link"
            icon="close-circle"
            class="px-0 text-current remove_brn" />

        <a-drawer
            :title="driwerTitle"
            class="workg_select_driwer"
            :width="windowWidth > 480 ? 380 : windowWidth"
            :destroyOnClose="true"
            :zIndex="99999"
            @close="visible = false"
            :visible="visible">
            <div class="drawer_header">
                <a-input-search
                    :loading="searchLoading"
                    v-model="search"
                    @input="onSearch"
                    :placeholder="$t('task.project_name')" />
            </div>
            <div class="drawer_body pr_scroll">
                <div class="drawer_scroll">
                    <!--<OldSelected 
                        ref="projectOldSelector"
                        :itemSelect="selectWork"
                        avatarField="workgroup_logo"
                        titleField="name"
                        avatarIcon="team"
                        dbId="project"
                        :getPopupContainer="getPopupContainer" />-->
                    <ul class="bordered-items select-none">
                        <li 
                            class="cursor-pointer item px-3 py-3 flex items-center justify-between" 
                            @click="selectWork(work)" 
                            v-for="(work, index) in workList" 
                            :key="index">
                            <div class="flex items-center justify-between w-full truncate">
                                <div class="flex items-center truncate">
                                    <div>
                                        <a-avatar 
                                            :size="30" 
                                            icon="team" 
                                            :src="workgroupLogoPath(work)" />
                                    </div>
                                    <div class="pl-2 truncate">
                                        {{work.name}}
                                    </div>
                                </div>
                                <div class="pl-2">
                                    <a-radio :checked="checkSelected(work)" />
                                </div>
                            </div>
                        </li>
                    </ul>
                    <Loader
                        class="chat__active-chats"
                        rowClass="px-2 lg:px-4 py-3"
                        v-if="loading && page === 1"
                        titleFull
                        hideParagraph
                        :skeletonRow="7" />
                    <infinite-loading ref="userInfinite" @infinite="getWorkList" v-bind:distance="10">
                        <div slot="spinner"><a-spin v-if="page !== 1" /></div>
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                    </infinite-loading>
                </div>
            </div>
            <div class="drawer_footer flex items-center">
                <a-button
                    block
                    type="ui"
                    ghost
                    class="px-8"
                    @click="visible = false">
                    {{$t('task.close')}}
                </a-button>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import Loader from '../Loader.vue'
//import OldSelected from '@apps/DrawerSelect/OldSelected.vue'
let timer = null
export default {
    components: {
        InfiniteLoading,
        //OldSelected,
        Loader
    },
    props: {
        value: {
            type: Array
        },
        title: {
            type: String,
            default: ''
        },
        inputSize: {
            type: String,
            default: 'default'
        },
        selectProject: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            driwerTitle: this.title ? this.title : this.$t('task.select_project'),
            search: '',
            visible: false,
            searchLoading: false,
            scrollStatus: true,
            page: 0,
            loading: false,
            workList: []
        }
    },
    computed: {
        size() {
            if(this.inputSize === 'large')
                return 'ant-input-lg'
            else
                return 'default'
        },
        windowWidth() {
            return this.$store.state.windowWidth
        }
    },
    methods: {
        getPopupContainer() {
            return document.querySelector('.pr_scroll')
        },
        onSearch() {
            clearTimeout(timer)
            timer = setTimeout(() => {
                this.scrollStatus = true
                this.page = 0
                this.workList = []
                if(this.search.length && this.search.length > 2) {
                    this.getWorkList(this.search)
                } else {
                    this.getWorkList()
                }
            }, 800)
        },
        clear() {
            this.$emit('input', [])
            this.selectProject([])
        },
        selectWork(work) {
            const selectedList = [...this.value]
            const index = selectedList.findIndex(f => f.id === work.id)
            if(index !== -1)
                selectedList.splice(index, 1)
            else
                selectedList.push(work)
            this.$emit('input', selectedList)
            this.selectProject(selectedList)
            //this.$refs.projectOldSelector.saveSelect(work)
            //this.visible = false
        },
        checkSelected(work) {
            const find = this.value.find(f => f.id === work.id)
            return find ? true : false
        },
        open() {
            this.visible = true
        },
        async getWorkList($state = null) {
            if(!this.loading && this.scrollStatus && this.visible) {
                try {
                    this.loading = true
                    this.page = this.page+1
                    let params = {
                        page_size: 15,
                        page: this.page,
                        is_project: 1,
                        page_name: 'project_drawer'
                    }
                    if(this.search) {
                        params.workgroups_name = this.search
                        this.searchLoading = true
                    }

                    const { data } = await this.$http.get('/work_groups/workgroups/', { params })
                    if(data && data.results.length)
                        this.workList = this.workList.concat(data.results)
                    if(!data.next) {
                        if($state)
                            $state.complete()
                        this.scrollStatus = false
                    } else {
                        if($state)
                            $state.loaded()
                    }
                } catch(e) {

                } finally {
                    if(this.search)
                        this.searchLoading = false
                    this.loading = false
                }
            } else {
                if($state)
                    $state.complete()
            }
        },
        workgroupLogoPath(workgroup) {
            return workgroup?.workgroup_logo?.path || ''
        },
    }
}
</script>

<style lang="scss" scoped>
.change_btn{
    height: 22px;
}
</style>

<style lang="scss">
.workg_draw_input{
    .remove_brn{
        right: 0;
        top: 50%;
        position: absolute;
        margin-top: -16px;
    }
    .tag_block{
        max-width: 300px;
        margin-bottom: 3px;
    }
    &.ant-input-lg{
        height: initial;
        min-height: 40px;
    }
}
.workg_select_driwer{
    .ant-drawer-content,
    .ant-drawer-wrapper-body{
        overflow: hidden;
    }
    .ant-drawer-body{
        padding: 0px;
        height: calc(100% - 40px);
    }
    .drawer_footer{
        border-top: 1px solid var(--borderColor);
        height: 40px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .drawer_header{
        border-bottom: 1px solid var(--borderColor);
        input{
            border-radius: 0px;
            height: 42px;
            border: 0px;
        }
    }
    .drawer_body{
        height: calc(100% - 83px);
        .drawer_scroll{
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            .item{
                &:not(:last-child){
                    border-bottom: 1px solid var(--borderColor);
                }
                &:hover{
                    background: var(--hoverBg);
                }
                .name{
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
</style>

