<template>
    <div class="" :class="useBackground && 'useBackground'">
        <div class="panel">
            <p class="text-xl">Стоимость, тыс</p>
            <div class="flex justify-between items-center">
            </div>
            <apexchart 
                type="bar" 
                height="250" 
                class="w-full"
                :options="chartOptions" 
                :series="series" />
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapState } from 'vuex'
import eventBus from '@/utils/eventBus'
export default {
    props: {
        fakeData: {
            type: Object,
            default: null,
        },
        item: {
            type: Object,
            // required: true,
            default: null,
        },
        graphColor: {
            type: String,
            default: '#f9f9f9'
        },
        useBackground: {
            type: Boolean,
            default: true
        }
    },
    components: {
        apexchart: VueApexCharts
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth
        }),

        // series() {
        //     return [{
        //         // name: 'Inflation',
        //         data: [12,10,10,10,10,10,10,10,10,10,21]
        //     }]
        // },
        chartSize() {
            if(this.windowWidth < 1800) {
                if(this.windowWidth < 1650) {
                    if(this.windowWidth < 768) {
                        return {
                            width: '230px',
                            height: '500px'
                        }
                    } else {
                        return {
                            width: '180px',
                            height: '180px'
                        }
                    }
                } else {
                    return {
                        width: '200px',
                        height: '200px'
                    }
                }
            } else {
                return {
                    width: '230px',
                    height: '230px'
                }
            }
        },
        // allPrice() {
        //     return Number(this.item.funds)
        // },
        // totalAmount() {
        //     return this.item.funding_sources.reduce((sum, each) => sum + Number(each.amount), 0)
        // },
        // allPriceStat() {
        //     return this.allPrice - this.totalAmount
        // },
        labels() {
            // let labels = this.item.funding_sources.map(each => each.funding_source.name)
            // labels.push('')
            // return labels
            return ["РБ – республиканский бюджет","За счет инвестора","Планируется","МБ – местный бюджет","МБ – местный бюджет","НФ – Национальный Фонд","Планируется","РБ – республиканский бюджет","За счет инвестора","ДИ – дополнительные инвестиции",""]
        },
        // series() {
        //     let series = this.item.funding_sources.map(each => Number(each.amount))
        //     series.push(this.allPriceStat)
        //     return series
        // },
        // percent() {
        //     return this.allPrice ? parseInt((this.totalAmount * 100/this.allPrice).toFixed(2)) : 0
        // },
        palette() {
            const numColors = this.item.funding_sources.length
            const palette = []
            const saturation = 60
            const lightness = 50

            for (let i = 0; i < numColors; i++) {
                const hue = Math.round((360 / numColors) * i)
                palette.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
            }
            palette.push(this.graphColor)
            return palette
        }
    },
    data() {
        return {
            tab: 1,
            seriesData: [],
            apexchartKey: new Date(),
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 250,
                    toolbar: {
                        show: false,
                    },
                },
                grid: {
                    show: true,
                    strokeDashArray: 10,
                    // borderColor: this.appTheme.primaryOp2,
                },
                // colors: [this.appTheme.primary],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                        borderRadiusApplication: 'end',
                        borderRadiusWhenStacked: 'last',
                        columnWidth: '50%',
                        dataLabels: {
                            position: 'top',
                        },
                    },
                },
                dataLabels: {
                    offsetY: 10,
                    style: {
                        fontSize: '14px',
                        // fontFamily: this.appTheme.fontFamily,
                        fontWeight: 400,
                    },
                },
                xaxis: {
                    tickPlacement: 'between',
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        show: false,
                    },
                    type: 'category',
                    categories: [
                        ['Плановые общие', 'трудозатраты'],
                        ['Фактические', 'трудозатраты'],
                        'Отклонение',
                    ],
                    labels: {
                        hideOverlappingLabels: false,
                        maxHeight: 120,
                        trim: false,
                        rotate: 0,
                        minWidth: 0,
                        maxWidth: 500,
                        style: {
                            // colors: [this.appTheme.textPrimary],
                            // fontFamily: this.appTheme.fontFamily,
                            fontSize: '13px',
                        },
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
                yaxis: {
                    labels: {
                        offsetX: -5,
                        offsetY: 4,
                        style: {
                            // colors: [this.appTheme.textPrimary],
                            // fontFamily: this.appTheme.fontFamily,
                            fontSize: '14px',
                            cssClass: 'opacity-60',
                        },
                    },
                },
                tooltip: {
                    style: {
                        // fontFamily: this.appTheme.fontFamily,
                    },
                    marker: {
                        show: false,
                    },
                },
            },
            series: [
                {
                    name: 'Время на задачи',
                    data: [
                        {
                            x: 'Плановые общие трудозатраты',
                            y: 549,
                        },
                        {
                            x: 'Фактические трудозатраты',
                            y: 324,
                        },
                        {
                            x: 'Отклонение',
                            y: 321,
                        },
                    ],
                },
            ],
        }
    },
    watch: {
        item: {
            handler: 'updateChartData',
            immediate: true,
            deep: true,
        },
    },
    methods: {
        updateChartData() {
            // if (this.item && this.item.funding_sources) {
            this.apexchartKey = Date.now(),
            this.chartOptions.labels = this.labels
            this.seriesData = this.series
            this.chartOptions.colors = this.palette
            // }
        }        
    },
    mounted() {
        eventBus.$on('update_price_chart', () => {
            this.updateChartData()
        })
    },
    beforeDestroy() {
        eventBus.$off('update_price_chart')
    }
}
</script>

<style lang="scss" scoped>

.panel {
    padding: 25px 30px;
    max-height: 330px;
    background-color: #FAFAFA;
    border-radius: 12px;
}

.invest_graph{
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
        grid-template-columns: 160px 1fr;
    }
    @media (min-width: 1600px) {
        grid-template-columns: 160px 1fr;
    }
    @media (min-width: 1800px) {
        grid-template-columns: 200px 1fr;
    }
    &__right{
        display: flex;
        justify-content: flex-start;
    }
    &.useBackground{
        &::v-deep{
            circle{
                fill: #f9f9f9;
            }
        }
    }
    &::v-deep{
        .chart{
            margin-left: -20px;
        }
    }
    .graph_list{
        margin-top: 10px;
        font-size: 13px;
        &__item{
            display: flex;
            align-items: baseline;
            &:not(:last-child){
                margin-bottom: 10px;
            }
            .list_marker{
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #f9f9f9;
                margin-right: 10px;
            }
        }
    }
    .graph_label{
        color: #000000;
        opacity: 0.6;
        font-size: 13px;
        margin-bottom: 6px;
    }
    .graph_value{
        color: #000000;
        font-size: 24px;
        line-height: 24px;
    }
}
</style>